import axios from "axios";
const instance = axios.create({
    withCredentials: true,
})

const getRequest = async (url, data) => {
    try {
        const res = await instance.get(url, data)
        return res
    } catch (error) {
        return error
    }
}
const postRequest = async (url, data) => {
    try {
        const res=await instance.post(url,data)
        return res
    } catch (error) {
        return error
    }
}
export { getRequest, postRequest }