import moment from 'moment/moment'
import React from 'react'
import { postRequest } from './axios'
import Request from '../request'
import { toast } from 'react-toastify'
import Table from './Table'

function TableList({ data }) {
    const claimBtn = async (id, amount) => {
        const data1 = {
            "voucher_ids": [id]
        }
        const res = await postRequest(Request.verifyTransporter, data1)
        if (res.data?.apiresponse?.type == "OK") {
            toast.success(res.data.apiresponse?.message)
            window.location.reload()
        } else {
            toast.error(res?.data?.apiresponse?.message)
        }
    }
    const checkBtn = async (id, amount) => {
        const params = {
            voucher_id: id,
            amount: amount
        }
        const res = await postRequest(Request.claimTransporter, params)
        if (res?.data?.apiresponse?.type == "OK") {
            toast.success(res?.data?.apiresponse?.message)
        } else {
            toast.error(res?.data?.apiresponse?.message)
        }
    }
    // const remainingTime = (time) => {
    //     // console.log("time", time)
    //     let remainingTime = time * 1000 + 172800000
    //     const currentTime = new Date().getTime()
    //     console.log("remaining time", remainingTime - currentTime)
    //     const diffTime = remainingTime - currentTime
    //     if (diffTime > 0) {
    //         if (diffTime < 60 * 1000) {
    //             return Math.floor(diffTime / 1000) + " seconds" + " remaining"
    //         } else if (diffTime < 60 * 60 * 1000) {
    //             return Math.floor(diffTime / (60 * 1000)) + " minutes" + " remaining"
    //         } else if (diffTime < 24 * 60 * 60 * 1000) {
    //             return Math.floor(diffTime / (60 * 60 * 1000)) + " hours" + " remaining"
    //         } 
    //         else {
    //             return Math.floor(diffTime / (24 * 60 * 60 * 1000)) + " Day" + " remaining"
    //         }
    //     }
    // }
    function remainingTime(date) {
        date = new Date(date * 1000 + 172800000);
        const now = moment();
        const targetDate = moment(date, "DD-MMM-YYYY hh:mm:ss A");
        const diff = now.diff(targetDate, 'minutes');
        var formated_age;
        if (diff < 1) {
            formated_age = "completed";
        } else if (diff < 60) {
            formated_age = diff + (diff === 1 ? " min" : " mins") + " remaining";
        } else if (diff < 1440) {
            const hours = Math.floor(diff / 60);
            const minutes = diff % 60;
            formated_age = hours + (hours === 1 ? " hr" : " hrs") + " " + minutes + (minutes === 1 ? " min" : " mins") + " remaining";
        } else if (diff < 10080) {
            const days = Math.floor(diff / 1440);
            const hours = Math.floor((diff % 1440) / 60);
            const minutes = diff % 60;
            formated_age = days + (days === 1 ? " day" : " days") + " " + hours + (hours === 1 ? " hr" : " hrs") + " " + minutes + (minutes === 1 ? " min" : " mins") + " remaining";
        } else if (diff < 40320) {
            const weeks = Math.floor(diff / 10080);
            const days = Math.floor((diff % 10080) / 1440);
            const hours = Math.floor((diff % 1440) / 60);
            const minutes = diff % 60;
            formated_age = weeks + (weeks === 1 ? " week" : " weeks") + " " + days + (days === 1 ? " day" : " days") + " " + hours + (hours === 1 ? " hr" : " hrs") + " " + minutes + (minutes === 1 ? " min" : " mins") + " remaining";
        } else {
            formated_age = targetDate.format("DD-MMM-YYYY hh:mm:ss A");
        }
        return formated_age;
    }
    return (
        <div className='table-list'>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Details</th>
                        <th scope="col">From<br />To</th>
                        <th scope="col">Amount<br />Claim</th>

                    </tr>
                </thead>
                <tbody>
                    {data?.map((value, index) => {
                        return (
                            <Table remainingTime={remainingTime} key={index} value={value} index={index} checkBtn={checkBtn} />
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TableList