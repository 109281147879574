import logo from './logo.svg';
import './App.scss';
import Login from './components/Login';
import { Route, Routes, HashRouter } from "react-router-dom"
import List from './components/List';
import { getRequest } from './components/axios';
import Request from './request';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
function App() {
  const [whoamiData, setWhoamiData] = useState([])
  const [login, setLogin] = useState(true)
  const whoami = async () => {
    const res = await getRequest(Request.whoami)
    if (res?.data?.apiresponse?.code == "LOGGEDIN") {
      setLogin(false)
    }
    setWhoamiData(res.data)
  }
  useEffect(() => {
    whoami()
  }, [])
  return (
    <div className=''>
      <div className='suviapp'>
        <HashRouter>
          <Routes>
            {login ?
              <Route exact path="*" element={<Login setLogin={setLogin} whoami={whoami} />} /> :
              <Route exact path='*' element={<List whoami={whoami} whoamiData={whoamiData} setLogin={setLogin} />} />}
          </Routes>
        </HashRouter>
      </div>
    </div>
  );
}

export default App;
